(function ($) {
  $(document).ready(function () {
    $(function () {
      document.querySelector("body").classList.replace("no-js", "js");
      //     $('html').replace( 'no-js', 'js' );
    });
    $(document).on("introEnded", function () {
      var $typer_element = document.getElementsByClassName("typer")[0];
      if ($typer_element) {
        setTimeout(function () {
          var typed = new Typed(
            $typer_element,
            {
              strings: ["Live", "Play", "Eat", "Explore"],
              startDelay: 500,
              typeSpeed: 150,
              backSpeed: 100,
              backDelay: 1500,
              loop: true,
              cursorChar: "|",
              smartBackspace: false,
            },
            10000
          );
        });
      }
    });

    var images = $(".intro div").get();
    var images_length = $(images).length;

    // If the intro has images, play intro. Otherwise skip intro and load page.
    if (images_length >= 1) {
      start(1);
    } else {
      introEnd();
    }

    function start(counter) {
      if (counter <= images_length) {
        setTimeout(function () {
          $(images[counter]).removeClass("hidden");
          counter++;
          if (counter <= $(images).length) {
            start(counter);
          } else {
            // When all the images of intro are displayed.
            introEnd();
          }
        }, 2800 / counter);
        //    console.log(2800 / (counter));
      }
    }

    function introEnd() {
      $("body").removeClass("loading");
      $(".intro").addClass("hidden");
      $(".container").addClass("show");
      $(document).trigger("introEnded");
    }
  });

  // This will fire only when the Mailchimp form is successfuly sumitted and the field is reset.
  $("form#mc-embedded-subscribe-form").on("reset", function (e) {
    dataLayer.push({ event: "formSubmit" });
    return true;
  });
})(jQuery);
